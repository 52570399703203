import React from "react";
import { graphql, navigate } from "gatsby";
import { UserContext, handleLogin, isLoggedIn } from "@parallelpublicworks/entitysync";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import recaptchaSubmit from "../util/recaptchaSubmit";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      width: "100%",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  button: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  spinner: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
}));

let loadingTimeout = null;

function Login() {
  const classes = useStyles();
  const userContext = React.useContext(UserContext);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasError, setHasError] = React.useState(null);
  const [mail, setMail] = React.useState("");
  const [password, setPassword] = React.useState("");

  React.useEffect(() => {
    if (isLoggedIn([userContext])) {
      clearTimeout(loadingTimeout);
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleChange = (e, setState) => {
    if (e.target.value && e.target.value.length > 0) {
      setState(e.target.value);
    } else {
      setState("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.persist();
    if (!isLoggedIn([userContext])) {
      setIsLoading(true);
      setHasError(null);
      try {
        const valid = await recaptchaSubmit(executeRecaptcha);
        if (!valid) throw new Error("Failed recaptcha");
        handleLogin(
          [
            userContext,
            function () {
              setIsLoading(false);
            },
          ],
          e
        );
        loadingTimeout = setTimeout(() => {
          setIsLoading(false);
          setHasError("An error occurred");
        }, 6000);
      } catch (error) {
        setIsLoading(false);
        setHasError(error.message);
      }
    }
  };

  return (
    <form
      noValidate
      autoComplete="off"
      className={classes.root}
      onSubmit={(e) => handleSubmit(e)}
    >
      {isLoggedIn([userContext]) && (
        <div className={classes.spinner}>
          <CircularProgress />
        </div>
      )}
      {!isLoggedIn([userContext]) && (
        <Container maxWidth="sm" className={classes.container}>
          {hasError !== null && <Alert severity="error">{hasError}</Alert>}
          <TextField
            label="Username *"
            variant="outlined"
            value={mail}
            onChange={(e) => handleChange(e, setMail, false)}
            name="username"
          />
          <TextField
            label="Password *"
            variant="outlined"
            type="password"
            name="password"
            value={password}
            onChange={(e) => handleChange(e, setPassword, false)}
          />
          <Button
            variant="contained"
            color="primary"
            disableElevation
            size="large"
            className={classes.button}
            type="submit"
            disabled={isLoading}
          >
            Login
          </Button>
        </Container>
      )}
    </form>
  );
}

export default Login;

export const query = graphql`
  {
    allMarkdownRemark(filter: { frontmatter: { slug: { eq: "login" } } }) {
      nodes {
        frontmatter {
          title
        }
      }
    }
  }
`;
